import React from 'react';
import IncomeForm from '../components/IncomeForm'
import axios from 'axios'
import { Table, Input,Form, Button,Select,DatePicker,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Tabs, Card } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,PlusOutlined,DeleteOutlined,EditOutlined,PrinterOutlined,FundViewOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import IncomePaymentForm from '../components/IncomePaymentForm'
import moment from 'moment';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { RangePicker } = DatePicker;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''
const { TabPane } = Tabs;
const { Option } = Select;


class QuickTransact extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    viewpaymentmodal:false,
    incomedetails:{},
    userprofile:{},
    datarequested:true,
    userrights:{},
    datasearch:false,
    dateone:'',
    datetwo:'',
    totaltobepaid:0,
    totalpaid:0,
    totalbalance:0,
    transactions:[],
    till:[],
    incomes:[],
    loanapplications:[],
    loanrepayments:[],
    members:[],
    updateformvisible:false,
    selectedaccountobject:{},
    accountbalance:0,
    deletelist:[],
    deletemode:'',

    staffs: [],
    creditofficer:'',
    selected_loan:'',
    loanissues: [],

    transaction_amount:0,
    transaction_details:'',
    transaction_account:'',
    transaction_account_obj:{},
    transaction_type:'',
    transaction_subaccount:'',
    transaction_date:moment().format(dateFormat).toString(),

  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })
        //this.setState({datarequested:false})
        this.setState({datarequested:false})
    })

}


closepaymentmodal = () => {
this.setState({ viewpaymentmodal: false });
}

  

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


  render() {

      var transactioncolumns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Account No',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },  
        {
          title: 'Acc Name',
          dataIndex: 'FullName',
          key: 'id',
        },
        {
          title: 'Teller',
          dataIndex: 'user',
          key: 'id',
        },
        {
          title: 'Slip No',
          dataIndex: 'slip_number',
          key: 'id',
        },
        {
          title: 'Debit',
          dataIndex: 'debit',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Credit',
          dataIndex: 'credit',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Details',
          dataIndex: 'transaction_details',
          key: 'id',
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/transactions/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/transactions/?sacco=${sacco}`)
              .then(res => {
                  this.setState({
                    transactions:res.data
                  })
              })

            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="transactions"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }

          </p>,
        }
  
      ];


      var tillcolumns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Account',
          dataIndex: 'account',
          key: 'id',
          ...this.getColumnSearchProps('account'),
        },  
        {
          title: 'Teller',
          dataIndex: 'user',
          key: 'id',
        },
        {
          title: 'Debit',
          dataIndex: 'Debit',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Credit',
          dataIndex: 'Credit',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/till/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/till/?sacco=${sacco}`)
              .then(res => {
                  this.setState({
                    till:res.data
                  })
              })

            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="till"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }

          </p>,
        }
  
      ];



      var incomecolumns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Income Item',
          dataIndex: 'income_item',
          key: 'id',
          ...this.getColumnSearchProps('income_item'),
        },
        {
          title: 'Income From',
          dataIndex: 'incomefrom_name',
          key: 'id',
          ...this.getColumnSearchProps('incomefrom_name'),

        },
        {
          title: 'Amount',
          dataIndex: 'total_to_be_paid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Details',
          dataIndex: 'details',
          key: 'id',
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/incomes/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/incomes/?sacco=${sacco}`)
              .then(res => {
                  this.setState({
                    incomes:res.data
                  })
              })
            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>


          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="incomes"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }

          </p>,
        }
  
      ];


      var loanapplicationscolumns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'AccountNumber',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'AccountName',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),

        },
        {
          title: 'Loan Amount',
          dataIndex: 'loan_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/loanapplications/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}`)
              .then(res => {
                  this.setState({
                    loanapplications:res.data
                  })
              })
            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="loanaplications"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }

          </p>,
        }
  
      ];


      var loanrepaymentscolumns = [
        {
          title: 'AccountNumber',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'AccountName',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },

        {
          title: 'Time Stamp (Actual)',
          dataIndex: 'time_stamp',
          key: 'id',
          ...this.getColumnSearchProps('time_stamp'),
        },
        {
          title: 'Date (Shedule date)',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Loan Issue date',
          dataIndex: 'loanissuedate',
          key: 'id',
          ...this.getColumnSearchProps('loanissuedate'),
        },

        {
          title: 'Loan Amount',
          dataIndex: 'loanissueamount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Principle Repaid',
          dataIndex: 'principle_paid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Interest Repaid',
          dataIndex: 'interest_paid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/loanrepayments/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/loanrepayments/?sacco=${sacco}&description=${'Loan Repayment'}`)
              .then(res => {
                  this.setState({
                    loanrepayments:res.data
                  })
              })
            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="loanrepayments"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }

          </p>,
        }
  
      ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin size="large">
            <div className="content" />
          </Spin>       
          </div>
      )

    }else{
      return(
        <div>

    

      <Row >

      <Col xs="12" sm="6" lg="6">
         <Card>
         <h4>Quick Transaction form</h4>

        <Form
            onFinish={(event) => {
            
                this.setState({datarequested:true})
                let form_data = new FormData();
                form_data.append('transaction_amount', this.state.transaction_amount);
                form_data.append('transaction_details',this.state.transaction_details);

                form_data.append('transaction_account', this.state.transaction_account);
                form_data.append('transaction_type', this.state.transaction_type);
                form_data.append('transaction_subaccount', this.state.transaction_subaccount);
                form_data.append('transaction_date', this.state.transaction_date);
                form_data.append('bizuserid', bizuserid);

                axios.post(serverconfig.backendserverurl+'/customqueries/quick_transact', form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
              .then(res =>  {
                if(res.data.message=="success"){
                  message.info(res.data.message)
                  this.setState({datarequested:false})
            
                }else{
                  message.info("Some thing went wrong")
                }
              }       
            
              )
              .catch(error => console.log(error))
              
            }} 
          >

            <FormItem label="Member account"
              name="transaction_account"
              rules={[
                {
                  required: true,
                  message: 'Please Select transaction_account',
                },
              ]}
            >
                <Select placeholder="transaction_account" 
                 value={this.state.transaction_account} 
                  onChange={(val)=>{
                    this.setState({transaction_account:val})

                    axios.get(`${serverconfig.backendserverurl}/api/members/${val}`)
                    .then(res => {  
                        this.setState({selectedaccountobject: res.data})
                    })
                  }} 
                  showSearch
                  optionFilterProp="children"
                  >
                  {this.state.members.map(
                    (mbr)=>(
                      <Option value={mbr.id}>{mbr.FullName} | {mbr.AccountNumber}</Option>
                    ))}
                </Select>
            </FormItem>

            <FormItem label='transaction_amount'
          >
            <Input name="transaction_amount" type='number'  placeholder="transaction_amount" value={this.state.transaction_amount} onChange={(val)=>{this.setState({transaction_amount:val.target.value})}}/>
          </FormItem>

            <FormItem label='transaction_details'
            >
              <Input name="transaction_details"  placeholder="transaction_details" value={this.state.transaction_details} onChange={(val)=>{this.setState({transaction_details:val.target.value})}}/>
            </FormItem>


            <FormItem label="transaction_date"
              >
                <DatePicker value={moment()} 
                onChange={(date, dateString) => this.setState({ transaction_date: dateString})} format={dateFormat} />
            </FormItem>


            <FormItem label="Transaction type"
              name="transaction_type"
              rules={[
                {
                  required: true,
                  message: 'Please Select transaction_type',
                },
              ]}
            >
                <Select placeholder="transaction_type"  value={this.state.transaction_type} onChange={(val)=>{this.setState({transaction_type:val})}} >
                  <Option value={'debit'}>Debit</Option>
                  <Option value={'credit'}>Credit</Option>
                </Select>
            </FormItem>

            <FormItem label="Select subaccount"
              name="transaction_subaccount"
              rules={[
                {
                  required: true,
                  message: 'Please Select transaction_subaccount',
                },
              ]}
            >
                <Select placeholder="transaction_subaccount"  
                value={this.state.transaction_subaccount} 
                onChange={(val)=>{this.setState({transaction_subaccount:val})}} >
                  <Option value={'savings'}>Savings</Option>
                  <Option value={'reserve'}>Savings loan security(Reserve)</Option>
                  <Option value={'loan'}>Loan</Option>

{/*                  <Option value={'shares'}>Shares</Option>
                  <Option value={'reserve'}>Savings loan security(Reserve)</Option>
                  <Option value={'loan'}>Loan</Option>
                  <Option value={'loanfine'}>Loan fine</Option> */}
                </Select>
            </FormItem>

        <FormItem>
          <Button  type="primary" htmlType="submit">Create transaction</Button>
        </FormItem>
      </Form>

      </Card>

      </Col>

      <Col xs="12" sm="6" lg="6">
                   <Card>
                   <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Savings Balance:<CurrencyFormat value={this.state.selectedaccountobject.UserBalance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Loan balance:<CurrencyFormat value={this.state.selectedaccountobject.loan_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Sharesamount balance:<CurrencyFormat value={this.state.selectedaccountobject.sharesamount_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Shares number balance:{this.state.selectedaccountobject.sharesnumber_balance}</h4>
                    <h4>Savings reserve:{this.state.selectedaccountobject.savingsloan_security_balance}</h4>

                   </Card>

                </Col>
            </Row>

      <Modal              
        visible={this.state.updateformvisible}
        title={"Member Account Balance Update Form"}
        onCancel={()=>{this.setState({updateformvisible:false})}}
        style={{width:1000}}
        footer={[
          <Button key="back" onClick={()=>{this.setState({updateformvisible:false})}}>
            Cancel
          </Button>
          ]}
          >

          <FormItem label='Account Balance'
          >
            <Input name="paidinby"  placeholder="Name" value={this.state.accountbalance} onChange={(val)=>{this.setState({accountbalance:val.target.value})}}/>
          </FormItem>

          <FormItem label="Select subaccount"
            >
                <Select placeholder="transaction_subaccount"  
                value={this.state.transaction_subaccount} 
                onChange={(val)=>{this.setState({transaction_subaccount:val})}} >
                  <Option value={'savings'}>Savings</Option>
                  <Option value={'reserve'}>Savings loan security(Reserve)</Option>

                </Select>
            </FormItem>


          <FormItem>
            <Button  type="primary" htmlType='button'
            onClick={()=>{
              
              if(this.state.transaction_subaccount==""){
                message.error("subaccount missing")

              }else{

                let form_data = new FormData();
                form_data.append('accountid',this.state.selectedaccountobject.id);
                form_data.append('newbalance', this.state.accountbalance);
                form_data.append('transaction_subaccount', this.state.transaction_subaccount);
  
                axios.post(serverconfig.backendserverurl+'/customqueries/updateaccountbalance', form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                  })
                .then(res => {
                  console.log(res.data)
                  this.setState({updateformvisible:false})  
  
                  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
                  .then(res => {
                      this.setState({
                        members:res.data
                      })
                  })
                })
                .catch(error => console.log(error))

              }
              
            }}
            
            >Update</Button>
          </FormItem>

      </Modal> 
    </div>
    )

    }
    
  }
}

export default QuickTransact; 
